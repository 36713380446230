import React, { useEffect, useState } from "react";

import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import { navigate } from "gatsby-link";
import { format, validate } from "rut.js";
import Swal from "sweetalert2";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import nurseService from "~/utils/api/v1/nurseService";
import estadoMunicipio from "~/utils/comunas/estado_municipio.json";
import { comunas } from "~/utils/comunas/names";
import { Specialty } from "~/utils/interfaces/Nurse";
import { NewNurse } from "~/utils/interfaces/User";
import { validateEmail } from "~/utils/regex";

const NewHealthTeamMember = (): JSX.Element => {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [country, setCountry] = useState<string>("Chile");
  const [comunasOptions, setComunasOptions] = useState<string[]>(comunas);
  const [specialtyOptions, setSpecialtyOptions] = useState<string[]>([]);
  const [userData, setUserData] = useState<NewNurse>({
    email: "",
    first_name: "",
    second_name: "",
    last_name: "",
    second_last_name: "",
    phone: "",
    rut: "",
    date_of_birth: "",
    gender: "male",
    pcr: false,
    blood: false,
    comunas: [],
    fingerprint_available: false,
    country: "cl",
    specialties: [],
  });

  useEffect(() => {
    const fetchNurseSpecialties = async () => {
      const res = await nurseService.fetchNurseSpecialties();
      const specialties = res.data.map((speciality: Specialty) => speciality.name);
      setSpecialtyOptions(specialties);
    };
    fetchNurseSpecialties();
  }, []);

  const createGenericIdMx = () => {
    const parsedBirthDate = userData.date_of_birth?.split("-").join("");
    const genericId = `${userData.first_name}${userData.last_name}${parsedBirthDate}`.toUpperCase();
    return genericId;
  };

  const onSubmit = async () => {
    setLoading(true);
    const dataNewNurse = userData.country === "mx" ? { ...userData, rut: createGenericIdMx() } : userData;
    const errors = checkErrors(dataNewNurse);
    if (errors) {
      setLoading(false);
      return;
    }

    try {
      const req = await nurseService.createNurse(dataNewNurse);
      Swal.fire({
        icon: req.status === 204 ? "error" : "success",
        title: req.status === 204 ? "Tomador con ese RUT ya existe en la base de datos" : "Tomador creado con éxito!",
        text: req.data.msg,
        showConfirmButton: true,
        didClose: () => {
          navigate(-1);
        },
      });
    } catch (err) {
      console.error(err);
      setErrors([err.message]);
    }

    setLoading(false);
  };

  const formChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    const attribute = event.target.id;
    setUserData((prevState: NewNurse): NewNurse => {
      const newState = { ...prevState };
      newState[attribute] = attribute === "rut" ? format(newValue) : newValue;
      return newState;
    });
  };

  const changeCountry = (event): void => {
    setCountry(event.target.value);
    if (event.target.value === "Chile") {
      setComunasOptions(comunas);
      setUserData({ ...userData, country: "cl" });
    } else if (event.target.value === "México") {
      setUserData({ ...userData, country: "mx" });
      setComunasOptions([...estadoMunicipio["Ciudad de México"], ...estadoMunicipio["Estado de México"]]);
    }
  };

  const genderChange = (event): void => {
    setUserData((prevState: NewNurse): NewNurse => {
      const newState = { ...prevState };
      newState.gender = event.target.value;
      return newState;
    });
  };

  const checkErrors = (data): boolean => {
    const newErrors: string[] = [];

    // check for errors in the form
    if (data.first_name.length === 0) {
      newErrors.push("El nombre es obligatorio");
    }
    if (data.last_name.length === 0) {
      newErrors.push("El apellido es obligatorio");
    }
    if (data.country === "cl" && !validate(data.rut ? data.rut : "")) {
      newErrors.push("El RUT no es válido");
    }
    if (!validateEmail(data.email)) {
      newErrors.push("El email no es válido");
    }

    setErrors(newErrors);
    return newErrors.length ? true : false;
  };

  return (
    <PrivateRoute>
      <div className="flex justify-center">
        <div className="p-12 rounded-lg bg-white border border-gray-200 flex flex-col">
          <h2 className="mb-8">Crear nuevo miembro del HealthTeam</h2>

          <div className="text-red-500 flex justify-center">
            <ul>
              {errors.map((i, key) => (
                <li key={key}>{i}</li>
              ))}
            </ul>
          </div>

          <div className="flex flex-row flex-nowrap items-center justify-center my-4 gap-x-4">
            <FormControl>
              <Select
                value={country}
                onChange={changeCountry}
                className="w-20"
              >
                {["Chile", "México"].map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>País</FormHelperText>
            </FormControl>
            <FormControl fullWidth>
              <Select
                multiple
                fullWidth
                value={userData.comunas}
                onChange={(event) =>
                  setUserData((prevState: NewNurse): NewNurse => {
                    const newState = { ...prevState };
                    newState.comunas = event.target.value as string[];
                    return newState;
                  })
                }
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div className="flex flex-wrap max-w-500">
                    {(selected as string[]).map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        className="m-2"
                      />
                    ))}
                  </div>
                )}
              >
                {comunasOptions.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{country === "Chile" ? "Comunas" : "Alcaldías"}</FormHelperText>
            </FormControl>
          </div>
          <div className="flex flex-row flex-nowrap items-center justify-center my-4 gap-x-4">
            <TextField
              id="first_name"
              onChange={formChange}
              value={userData.first_name}
              helperText="Primer nombre"
              fullWidth
            ></TextField>
            <TextField
              id="second_name"
              onChange={formChange}
              value={userData.second_name}
              helperText="Segundo nombre"
              fullWidth
            ></TextField>
          </div>
          <div className="flex flex-row flex-nowrap items-center justify-center my-4 gap-x-4">
            <TextField
              id="last_name"
              onChange={formChange}
              value={userData.last_name}
              helperText="Apellido"
              fullWidth
            ></TextField>
            <TextField
              id="second_last_name"
              onChange={formChange}
              value={userData.second_last_name}
              helperText="Segundo apellido"
              fullWidth
            ></TextField>
          </div>
          <div className="flex flex-row flex-nowrap items-center justify-center my-4 gap-x-4">
            <TextField
              id="email"
              onChange={formChange}
              value={userData.email}
              helperText="Email"
              fullWidth
            ></TextField>
          </div>
          <div className="flex flex-row flex-nowrap items-center justify-center my-4 gap-x-4">
            <TextField
              id="phone"
              onChange={formChange}
              value={userData.phone}
              helperText="Teléfono"
              fullWidth
            ></TextField>
            {country === "Chile" && (
              <TextField
                id="rut"
                onChange={formChange}
                value={userData.rut}
                helperText="RUT"
                fullWidth
              ></TextField>
            )}
          </div>
          <div className="flex flex-row flex-nowrap items-center justify-center my-4 gap-x-4">
            <FormControl fullWidth>
              <Select
                onChange={genderChange}
                value={userData.gender}
                fullWidth
              >
                <MenuItem value="male">Hombre</MenuItem>
                <MenuItem value="female">Mujer</MenuItem>
                <MenuItem value="other">Otro</MenuItem>
              </Select>
              <FormHelperText>Sexo</FormHelperText>
            </FormControl>
            <TextField
              fullWidth
              id="date_of_birth"
              helperText="Fecha de nacimiento"
              type="date"
              onChange={formChange}
              value={userData.date_of_birth}
            />
          </div>
          <div className="flex flex-row flex-nowrap items-center justify-center my-4 gap-x-4">
            <FormControl fullWidth>
              <Select
                multiple
                fullWidth
                value={userData.specialties}
                onChange={(event) =>
                  setUserData((prevState: NewNurse): NewNurse => {
                    const newState = { ...prevState };
                    newState.specialties = event.target.value as string[];
                    return newState;
                  })
                }
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div className="flex flex-wrap max-w-500">
                    {(selected as string[]).map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        className="m-2"
                      />
                    ))}
                  </div>
                )}
              >
                {specialtyOptions?.map((specialty) => (
                  <MenuItem
                    key={specialty}
                    value={specialty}
                  >
                    {specialty}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Especialidades</FormHelperText>
            </FormControl>
          </div>
          <div className="flex flex-row flex-nowrap items-center justify-center my-4 gap-x-4">
            <FormControlLabel
              control={
                <Switch
                  name="PCR"
                  color="primary"
                  checked={userData.pcr}
                  onChange={() =>
                    setUserData((prevState: NewNurse): NewNurse => {
                      const newState = { ...prevState };
                      newState.pcr = !newState.pcr;
                      return newState;
                    })
                  }
                />
              }
              label="PCR"
            />
            <FormControlLabel
              control={
                <Switch
                  name="Sangre"
                  color="primary"
                  checked={userData.blood}
                  onChange={() =>
                    setUserData((prevState: NewNurse): NewNurse => {
                      const newState = { ...prevState };
                      newState.blood = !newState.blood;
                      return newState;
                    })
                  }
                />
              }
              label="Sangre"
            />
            <FormControlLabel
              control={
                <Switch
                  name="¿Tiene huellero?"
                  color="primary"
                  checked={userData.fingerprint_available}
                  onChange={() =>
                    setUserData((prevState: NewNurse): NewNurse => {
                      const newState = { ...prevState };
                      newState.fingerprint_available = !newState.fingerprint_available;
                      return newState;
                    })
                  }
                />
              }
              label="¿Tiene huellero?"
            />
          </div>

          <div className="flex align-center justify-center my-8 gap-x-4">
            <button
              className="text-red-500 bg-white text-sm hover:opacity-70 hover:bg-red-500/10 uppercase border-[1.5px] border-red-500 rounded-md px-12 py-2"
              disabled={loading}
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancelar
            </button>
            <button
              className="text-[#039BE5] bg-white text-sm hover:opacity-70 uppercase border-[1.5px] border-[#039BE5] rounded-md px-12 py-2"
              disabled={loading}
              onClick={onSubmit}
            >
              Crear
            </button>
          </div>
        </div>
      </div>
    </PrivateRoute>
  );
};

export default NewHealthTeamMember;
